var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"form-container"},[_c('v-row',{staticClass:"py-3"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('h2',{staticClass:"heading-h2 mb-2",attrs:{"data-testid":"hub-form-header"}},[_vm._v(" "+_vm._s(_setup.t('Title_.page.addNewHub'))+" ")])])],1),_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('v-card',{staticClass:"px-6 py-4 shadow-regular"},[_c('v-form',{attrs:{"data-testid":"hub-form"},model:{value:(_setup.isFormValid),callback:function ($$v) {_setup.isFormValid=$$v},expression:"isFormValid"}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('h4',{staticClass:"heading-h4 mb-4",attrs:{"data-testid":"hub-form-subheader"}},[_vm._v(" "+_vm._s(_setup.t('Title_.subTitle.generalInformation'))+" ")])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.RegularTextInput,{attrs:{"data-testid":"hub-form-number-field","label":_setup.t('Msg_.hubNumber') + '*',"rules":[
                    _setup.rules.fieldRequired(
                      `${_setup.t('Msg_.hubNumber')} ${_setup.t('Msg_.rules.isRequired')}`
                    ),
                    _setup.rules.totalSize(4, _setup.t('Msg_.rules.mustHaveFourDigits')),
                    _setup.rules.onlyNumbers(_setup.t('Msg_.rules.onlyNumbers')),
                  ]},model:{value:(_setup.newHub.hubNumber),callback:function ($$v) {_vm.$set(_setup.newHub, "hubNumber", $$v)},expression:"newHub.hubNumber"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.RegularTextInput,{attrs:{"data-testid":"hub-form-name-field","label":_setup.t('Msg_.hubName') + '*',"rules":[
                    _setup.rules.fieldRequired(
                      `${_setup.t('Msg_.hubName')} ${_setup.t('Msg_.rules.isRequired')}`
                    ),
                    _setup.rules.valueSize(
                      30,
                      `${_setup.t('Msg_.hubName')} ${_setup.t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 30 ${_setup.t('Msg_.rules.characters')}`
                    ),
                  ]},model:{value:(_setup.newHub.name),callback:function ($$v) {_vm.$set(_setup.newHub, "name", $$v)},expression:"newHub.name"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.RegularTextInput,{attrs:{"data-testid":"hub-form-noventi-name-field","label":_setup.t('Msg_.noventiHubName') + '*',"rules":[
                    _setup.rules.fieldRequired(
                      `${_setup.t('Msg_.noventiHubName')} ${_setup.t(
                        'Msg_.rules.isRequired'
                      )}`
                    ),
                    _setup.rules.valueSize(
                      15,
                      `${_setup.t('Msg_.noventiHubName')} ${_setup.t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 15 ${_setup.t('Msg_.rules.characters')}`
                    ),
                  ]},model:{value:(_setup.newHub.noventiHubName),callback:function ($$v) {_vm.$set(_setup.newHub, "noventiHubName", $$v)},expression:"newHub.noventiHubName"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.RegularTextInput,{attrs:{"data-testid":"hub-form-county-field","label":_setup.t('Msg_.county') + '*',"rules":[
                    _setup.rules.fieldRequired(
                      `${_setup.t('Msg_.county')} ${_setup.t('Msg_.rules.isRequired')}`
                    ),
                    _setup.rules.valueSize(
                      100,
                      `${_setup.t('Msg_.hubName')} ${_setup.t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 100 ${_setup.t('Msg_.rules.characters')}`
                    ),
                  ]},model:{value:(_setup.newHub.county),callback:function ($$v) {_vm.$set(_setup.newHub, "county", $$v)},expression:"newHub.county"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.DropdownInput,{attrs:{"data-testid":"hub-form-state-dropdown","label":_setup.t('Msg_.state') + '*',"items":_setup.selectStateType,"rules":[
                    _setup.rules.fieldRequired(
                      `${_setup.t('Msg_.state')} ${_setup.t('Msg_.rules.isRequired')}`
                    ),
                  ]},model:{value:(_setup.newHub.state),callback:function ($$v) {_vm.$set(_setup.newHub, "state", $$v)},expression:"newHub.state"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.DropdownInput,{attrs:{"data-testid":"hub-form-region-dropdown","label":_setup.t('Msg_.region') + '*',"items":_setup.selectRegionType,"rules":[
                    _setup.rules.fieldRequired(
                      `${_setup.t('Msg_.region')} ${_setup.t('Msg_.rules.isRequired')}`
                    ),
                  ]},model:{value:(_setup.newHub.region),callback:function ($$v) {_vm.$set(_setup.newHub, "region", $$v)},expression:"newHub.region"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.DatePicker,{attrs:{"data-testid":"hub-form-operation-start-date-picker","label":_setup.t('Msg_.operationStart') + '*',"placeholder":_setup.t('Msg_.datePlaceholder'),"rules":[
                    _setup.rules.fieldRequired(
                      `${_setup.t('Msg_.operationStart')} ${_setup.t(
                        'Msg_.rules.isRequired'
                      )}`
                    ),
                  ]},model:{value:(_setup.newHub.operationStartDate),callback:function ($$v) {_vm.$set(_setup.newHub, "operationStartDate", $$v)},expression:"newHub.operationStartDate"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.DropdownInput,{attrs:{"data-testid":"hub-form-growth-type-dropdown","label":_setup.t('Msg_.growthType') + '*',"items":_setup.selectGrowthType,"rules":[
                    _setup.rules.fieldRequired(
                      `${_setup.t('Msg_.growthType')} ${_setup.t('Msg_.rules.isRequired')}`
                    ),
                  ]},model:{value:(_setup.newHub.growthType),callback:function ($$v) {_vm.$set(_setup.newHub, "growthType", $$v)},expression:"newHub.growthType"}})],1)],1)],1),_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"main-action-variant-btn",attrs:{"data-testid":"hub-form-cancel-btn","to":{ name: 'HubList' }}},[_vm._v(" "+_vm._s(_setup.t('Action_.buttons.cancel'))+" ")]),_c('v-btn',{staticClass:"main-action-btn ml-2",attrs:{"data-testid":"hub-form-continue-btn","disabled":!_setup.isFormValid},on:{"click":function($event){return _setup.toggleCreateNewHubDialog()}}},[_vm._v(" "+_vm._s(_setup.t('Action_.buttons.continue'))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }