<template>
  <v-container class="form-container">
    <v-row class="py-3">
      <v-col cols="auto">
        <h2 data-testid="hub-form-header" class="heading-h2 mb-2">
          {{ t('Title_.page.addNewHub') }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <v-card class="px-6 py-4 shadow-regular">
          <v-form data-testid="hub-form" v-model="isFormValid">
            <v-container>
              <v-row>
                <v-col>
                  <h4 data-testid="hub-form-subheader" class="heading-h4 mb-4">
                    {{ t('Title_.subTitle.generalInformation') }}
                  </h4>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <RegularTextInput
                    data-testid="hub-form-number-field"
                    :label="t('Msg_.hubNumber') + '*'"
                    v-model="newHub.hubNumber"
                    :rules="[
                      rules.fieldRequired(
                        `${t('Msg_.hubNumber')} ${t('Msg_.rules.isRequired')}`
                      ),
                      rules.totalSize(4, t('Msg_.rules.mustHaveFourDigits')),
                      rules.onlyNumbers(t('Msg_.rules.onlyNumbers')),
                    ]"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <RegularTextInput
                    data-testid="hub-form-name-field"
                    :label="t('Msg_.hubName') + '*'"
                    v-model="newHub.name"
                    :rules="[
                      rules.fieldRequired(
                        `${t('Msg_.hubName')} ${t('Msg_.rules.isRequired')}`
                      ),
                      rules.valueSize(
                        30,
                        `${t('Msg_.hubName')} ${t(
                          'Msg_.rules.fieldMustHaveLessThan'
                        )} 30 ${t('Msg_.rules.characters')}`
                      ),
                    ]"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <RegularTextInput
                    data-testid="hub-form-noventi-name-field"
                    :label="t('Msg_.noventiHubName') + '*'"
                    v-model="newHub.noventiHubName"
                    :rules="[
                      rules.fieldRequired(
                        `${t('Msg_.noventiHubName')} ${t(
                          'Msg_.rules.isRequired'
                        )}`
                      ),
                      rules.valueSize(
                        15,
                        `${t('Msg_.noventiHubName')} ${t(
                          'Msg_.rules.fieldMustHaveLessThan'
                        )} 15 ${t('Msg_.rules.characters')}`
                      ),
                    ]"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <RegularTextInput
                    data-testid="hub-form-county-field"
                    :label="t('Msg_.county') + '*'"
                    v-model="newHub.county"
                    :rules="[
                      rules.fieldRequired(
                        `${t('Msg_.county')} ${t('Msg_.rules.isRequired')}`
                      ),
                      rules.valueSize(
                        100,
                        `${t('Msg_.hubName')} ${t(
                          'Msg_.rules.fieldMustHaveLessThan'
                        )} 100 ${t('Msg_.rules.characters')}`
                      ),
                    ]"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <DropdownInput
                    data-testid="hub-form-state-dropdown"
                    :label="t('Msg_.state') + '*'"
                    :items="selectStateType"
                    v-model="newHub.state"
                    :rules="[
                      rules.fieldRequired(
                        `${t('Msg_.state')} ${t('Msg_.rules.isRequired')}`
                      ),
                    ]"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <DropdownInput
                    data-testid="hub-form-region-dropdown"
                    :label="t('Msg_.region') + '*'"
                    :items="selectRegionType"
                    v-model="newHub.region"
                    :rules="[
                      rules.fieldRequired(
                        `${t('Msg_.region')} ${t('Msg_.rules.isRequired')}`
                      ),
                    ]"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <DatePicker
                    data-testid="hub-form-operation-start-date-picker"
                    :label="t('Msg_.operationStart') + '*'"
                    :placeholder="t('Msg_.datePlaceholder')"
                    v-model="newHub.operationStartDate"
                    :rules="[
                      rules.fieldRequired(
                        `${t('Msg_.operationStart')} ${t(
                          'Msg_.rules.isRequired'
                        )}`
                      ),
                    ]"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <DropdownInput
                    data-testid="hub-form-growth-type-dropdown"
                    :label="t('Msg_.growthType') + '*'"
                    :items="selectGrowthType"
                    v-model="newHub.growthType"
                    :rules="[
                      rules.fieldRequired(
                        `${t('Msg_.growthType')} ${t('Msg_.rules.isRequired')}`
                      ),
                    ]"
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row>
                <v-col class="d-flex justify-end">
                  <v-btn
                    data-testid="hub-form-cancel-btn"
                    class="main-action-variant-btn"
                    :to="{ name: 'HubList' }"
                  >
                    {{ t('Action_.buttons.cancel') }}
                  </v-btn>
                  <v-btn
                    data-testid="hub-form-continue-btn"
                    class="main-action-btn ml-2"
                    :disabled="!isFormValid"
                    @click="toggleCreateNewHubDialog()"
                  >
                    {{ t('Action_.buttons.continue') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import languages from './locales/languages';
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import DatePicker from '@/components/shared/customComponents/inputs/date-picker.vue';
import { useRouter, onBeforeRouteLeave } from 'vue-router/composables';
import { useHubsStore } from '@/store/pinia/hubs.store.js';
import { usePeopleStore } from '@/store/pinia/people.store.js';
import { usePopupDialogStore } from '@/store/pinia/popup-dialog.store.js';
import { useI18n } from 'vue-i18n-bridge';
import helpers from '@/helpers/helpers';
import rules from '@/helpers/rules';
import { ref, computed, onBeforeUnmount } from 'vue';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';

const { trackPage } = amplitudeComposable();
const router = useRouter();
const hubsStore = useHubsStore();
const peopleStore = usePeopleStore();
const popupDialogStore = usePopupDialogStore();
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const isFormValid = ref(false);
const unchangedForm = ref(null);
const nextRoute = ref(null);
const ignoreFormChanges = ref(false);
const newHub = ref({
  hubNumber: null,
  name: null,
  noventiHubName: null,
  county: null,
  state: null,
  region: null,
  operationStartDate: null,
  growthType: null,
});

// --- Methods ---
const createHub = async () => {
  ignoreFormChanges.value = true;
  try {
    await hubsStore.createHub(newHub.value);
  } catch (error) {
    console.log(error);
  } finally {
    router.push({ name: 'HubList' });
  }
};

const toggleDiscardChangesDialog = (next) => {
  const actionBtn = () => {
    if (nextRoute.value) next();
  };

  const params = {
    type: 'confirmation',
    title: t('Action_.dialog.saveChanges.title'),
    message: t('Action_.dialog.saveChanges.body'),
    reinforcement: t('Action_.dialog.saveChanges.reinforcement'),
    actionBtnText: t('Action_.buttons.ok'),
    actionBtn: actionBtn,
  };

  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

const toggleCreateNewHubDialog = () => {
  const params = {
    title: t('Action_.dialog.createNewHub.title'),
    message: t('Action_.dialog.createNewHub.body'),
    reinforcement: t('Action_.dialog.createNewHub.reinforcement'),
    actionBtnText: t('Action_.buttons.confirm'),
    actionBtn: createHub,
  };

  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

const initializeForm = () => {
  unchangedForm.value = { ...newHub.value };
};

const isFormAltered = () => {
  if (unchangedForm.value) {
    return !helpers.compareTwoObjects(newHub.value, unchangedForm.value);
  }
  return false;
};

// --- Computed ---
const selectStateType = computed(() => {
  const onlyStates = peopleStore.peopleResources?.stateTypes.filter(
    ({ Value }) => Value !== 0
  );

  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectStateTypes'),
    onlyStates
  );
});

const selectRegionType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectRegionTypes'),
    peopleStore.peopleResources?.regionTypes
  );
});

const selectGrowthType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectGrowthTypes'),
    peopleStore.peopleResources?.growthTypes
  );
});

// --- Lifecycle hooks ---
initializeForm();
trackPage();
onBeforeUnmount(() => {
  nextRoute.value = null;
});

// --- Routing guard ---
onBeforeRouteLeave((to, _, next) => {
  nextRoute.value = null;
  if (!isFormAltered() || ignoreFormChanges.value) {
    nextRoute.value = to;
  }
  if (nextRoute.value) {
    next();
  } else {
    nextRoute.value = to;
    toggleDiscardChangesDialog(next);
  }
});
</script>

<style scoped lang="scss">
.form-container {
  max-width: 736px;
}
</style>
